import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Menu, Avatar, Card, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 400,
    minWidth: 400
  },
  avatar: {
    backgroundColor: red[500]
  },
  button: {
    padding: 0,
    minWidth: 0
  }
}));

const UserProfile = ({ user, logout }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    sessionStorage.clear();
  };

  return (
    <div className="usermenu">
      <Button
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {user ? (
          <Avatar>
            {user.given_name.substr(0, 1)}
            {user.family_name.substr(0, 1)}
          </Avatar>
        ) : (
          <Avatar />
        )}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Card className={classes.card}>
          <CardHeader
            avatar={
              user ? (
                <Avatar>
                  {user.given_name.substr(0, 1)}
                  {user.family_name.substr(0, 1)}
                </Avatar>
              ) : (
                <Avatar />
              )
            }
            title={user ? user.name : null}
            subheader={
              <Button
                size="small"
                className={classes.button}
                onClick={handleLogout}
              >
                Logout
              </Button>
            }
          />
        </Card>
      </Menu>
    </div>
  );
};

export default UserProfile;
