import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Slide
} from "@material-ui/core";
import { LocalHospital } from "@material-ui/icons";
import SubmitButton from "./SubmitButton";
import axios from "axios";
import io from "socket.io-client";
const socket = io(process.env.REACT_APP_API_URI)

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 400,
    padding: "30px"
  },
  avatar: {
    backgroundColor: red[500]
  },
  cardActions: {
    justifyContent: "center"
  }
}));

const EmergencyForm = ({ enqueueSnackbar, userData }) => {

  let { emergencyContact, online: onlineDistant } = userData;
  
  const [online, setOnline] = useState(onlineDistant)
  const [contact, setContact] = useState(emergencyContact);
  const [values, setValues] = useState(
    emergencyContact || {
      fullName: "",
      phoneNumber: "",
      relationship: ""
    }
  );
  useEffect(() => {
    socket.on('status change', payload => {
      const { online } = payload
      setOnline(online)
    })
  }, [online])

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const closed = !online && Object.keys(contact).length > 0

  const handleSubmit = async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      const { data: updatedUser } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URI}/users`,
        data: values
      });
      setContact(updatedUser);
      setSuccess(true);
      setLoading(false);
      enqueueSnackbar("Données mises à jour", { variant: "success" });
    }
  };

  const classes = useStyles();
  const updateField = event => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Slide direction="up" in={true}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              <LocalHospital />
            </Avatar>
          }
          title="Contact en cas d'urgence"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Veuillez renseigner une personne de confiance à contacter en cas
            d'urgence. Cette personne peut faire partie de votre famille ou non.
          </Typography>
          <TextField
            id="fullName"
            name="fullName"
            label="Nom complet"
            onChange={updateField}
            margin="normal"
            variant="outlined"
            defaultValue={values.fullName}
            fullWidth
            required
            disabled={closed}
          />
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label="Numéro de téléphone"
            onChange={updateField}
            margin="normal"
            variant="outlined"
            defaultValue={values.phoneNumber}
            fullWidth
            required
            disabled={closed}
          />
          <TextField
            id="relationship"
            name="relationship"
            label="Lien de parenté"
            onChange={updateField}
            margin="normal"
            variant="outlined"
            defaultValue={values.relationship}
            fullWidth
            required
            disabled={closed}
          />
        </CardContent>
        <CardActions className={classes.cardActions} disableSpacing>
          <SubmitButton
            handleSubmit={handleSubmit}
            loading={loading}
            success={success}
            disabled={
              !values.fullName ||
              !values.phoneNumber ||
              !values.relationship ||
              closed
            }
          />
        </CardActions>
      </Card>
    </Slide>
  );
};

export default EmergencyForm;
