import React, { useState, useEffect } from "react";
import "./App.css";
import EmergencyForm from "./components/EmergencyForm";
import LogoHeig from "./assets/logo-heig-vd.svg";
import UserProfile from "./components/UserProfile";
import { withSnackbar } from "notistack";

const App = ({ enqueueSnackbar, keycloak, userData }) => {
  const [user, setUser] = useState();
  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await keycloak.loadUserInfo();
      setUser(currentUser);
    };
    fetchUser();
  }, [keycloak]);
  return (
    <div className="App">
      <img className="logo" alt="Logo HEIG-VD" src={LogoHeig} />
      <EmergencyForm enqueueSnackbar={enqueueSnackbar} userData={userData} />
      <UserProfile user={user} logout={keycloak.logout} />
    </div>
  );
};

export default withSnackbar(App);
