import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import Keycloak from "keycloak-js";
import axios from "axios";
import config from "./config/keycloak.json";

const kc = Keycloak({
  url: config["auth-server-url"],
  realm: config.realm,
  clientId: config.resource
});
const token = localStorage.getItem("kc_token");
const refreshToken = localStorage.getItem("kc_refreshToken");

kc.init({
  onLoad: "login-required",
  promiseType: "native",
  token,
  refreshToken
}).then(async authenticated => {
  if (authenticated) {
    try {
      const { data: user } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URI}/users`
      });
      updateLocalStorage();
      ReactDOM.render(
        <SnackbarProvider>
          <App keycloak={kc} userData={user} />
        </SnackbarProvider>,
        document.getElementById("root")
      );
    } catch (e) {
      kc.logout();
    }
  }
});

axios.interceptors.request.use(config =>
  kc
    .updateToken(5)
    .then(refreshed => {
      if (refreshed) {
        updateLocalStorage();
      }
      config.headers.Authorization = "Bearer " + kc.token;
      return Promise.resolve(config);
    })
    .catch(() => {
      kc.login();
    })
);

const updateLocalStorage = () => {
  localStorage.setItem("kc_token", kc.token);
  localStorage.setItem("kc_refreshToken", kc.refreshToken);
};
